import { nanoid } from 'nanoid'
import Link from 'next/link'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { Icon } from 'src'
import { Button } from '../atoms/button/Button'
import { useAuth } from '../providers/AuthProvider'

const AUTH_NAV_LINKS = [
  {
    href: '/app/profile',
    label: 'drawer.profilScreen.title',
  },
  {
    href: '/app/subscription',
    label: 'drawer.subscriptionScreen.title',
  },
  {
    href: '/app/billing',
    label: 'drawer.billingScreen.title',
  },
]

export const Header = ({
  logo = {
    url: '/mLogoFull.png',
    width: '444',
    height: '111',
    alt: '',
  },
  headerLeft = [],
  headerRight = [],
  logoMobile = {
    url: '/mLogo.png',
    width: '95',
    height: '101',
    alt: '',
  },
  displayAuthOrProfileButton = true,
  isLogoClickable = true,
}) => {
  const { user, requestLogout } = useAuth()
  const { asPath } = useRouter()
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const { formatMessage: getI18nKey } = useIntl()

  useEffect(() => {
    setIsMenuOpen(false)
  }, [asPath])

  const isBusiness = asPath.includes('business')

  return (
    <header className="p-5 md:py-10 md:px-7 flex items-center justify-between">
      <div className="flex-1">
        <div className="hidden md:flex items-center space-x-14 pr-5">
          {!isLogoClickable ? (
            <div className="w-28">
              <img src={logo.url} width={logo.width} height={logo.height} alt="" />
            </div>
          ) : (
            <Link href="/">
              <a className="block w-28">
                <img src={logo.url} width={logo.width} height={logo.height} alt="" />
              </a>
            </Link>
          )}
          {headerLeft
            .filter(nav =>
              user
                ? user?.premium
                  ? nav.displayIfUserIsPremium
                  : nav.displayIfUserIsLogged
                : nav?.displayIfUserNotLogged ?? true,
            )
            .map(nav => (
              <Button
                href={nav.href}
                target={nav?.target ?? '_self'}
                buttonType={nav.buttonType}
                key={nanoid(5)}
                icon={nav.icon}
                className="relative"
              >
                {nav.label}
                {nav?.isNew && (
                  <span className="inline-block bg-heliotrope text-white uppercase p-1 px-2 text-xs scale-75 transform absolute rounded-xl -right-4 bottom-3/4">
                    New
                  </span>
                )}
              </Button>
            ))}
        </div>
      </div>
      <div className="flex-1 block md:hidden">
        {!isLogoClickable ? (
          <div className="m-auto w-8">
            <img src={logoMobile.url} width={logoMobile.width} height={logoMobile.height} alt="" />
          </div>
        ) : (
          <Link href="/">
            <a
              className={classnames('block m-auto w-8', {
                'w-auto': isBusiness,
              })}
            >
              <img
                src={logoMobile.url}
                width={logoMobile.width}
                height={logoMobile.height}
                alt=""
              />
            </a>
          </Link>
        )}
      </div>
      <div className="md:hidden flex-1 text-right">
        <button
          className={classnames({
            hidden: !isLogoClickable,
          })}
          onClick={() => setIsMenuOpen(true)}
        >
          <Icon name="burger" className="text-5xl text-white" />
        </button>
      </div>
      <div className="hidden md:block">
        <nav className="flex items-center space-x-5">
          {headerRight
            .filter(nav =>
              user
                ? user?.premium
                  ? nav.displayIfUserIsPremium
                  : nav.displayIfUserIsLogged
                : nav?.displayIfUserNotLogged ?? true,
            )
            .map(nav => (
              <Button
                href={nav.href}
                target={nav?.target ?? '_self'}
                buttonType={nav.buttonType}
                key={nanoid(5)}
                icon={nav.icon}
                className="relative"
              >
                {nav.label}
                {nav?.isNew && (
                  <span className="inline-block bg-heliotrope text-white uppercase p-1 px-2 text-xs scale-75 transform absolute rounded-xl -right-4 bottom-3/4">
                    New
                  </span>
                )}
              </Button>
            ))}

          {displayAuthOrProfileButton && !user && (
            <Button href={process.env.NEXT_PUBLIC_WEBAPP_URL} buttonType="transparent">
              {getI18nKey({ id: 'header.button.connect' })}
            </Button>
          )}
        </nav>
      </div>
      <div
        className={classnames(
          'flex w-screen md:hidden fixed inset-0 z-20 transform transition-transform',
          {
            'translate-x-full': !isMenuOpen,
            'translate-x-0': isMenuOpen,
          },
        )}
      >
        <div className="flex-1 bg-black bg-opacity-30" onClick={() => setIsMenuOpen(false)} />
        <div className="w-4/5 bg-tuna p-5">
          <div className="text-right space-y-8">
            <button onClick={() => setIsMenuOpen(false)}>
              <Icon name="close-off" className="text-4xl text-white" />
            </button>
            <div className="space-y-5">
              {headerLeft
                .filter(nav =>
                  user
                    ? user?.premium
                      ? nav.displayIfUserIsPremium
                      : nav.displayIfUserIsLogged
                    : nav?.displayIfUserNotLogged ?? true,
                )
                .map(nav => (
                  <Button
                    href={nav.href}
                    buttonType={nav.buttonType}
                    key={nanoid(5)}
                    icon={nav.icon}
                    className="relative"
                    target={nav.target ?? '_self'}
                  >
                    {nav.label}
                    {nav?.isNew && (
                      <span className="inline-block bg-heliotrope text-white uppercase p-1 px-2 text-xs scale-75 transform absolute rounded-xl left-0">
                        New
                      </span>
                    )}
                  </Button>
                ))}
              {headerRight
                .filter(nav =>
                  user
                    ? user?.premium
                      ? nav.displayIfUserIsPremium
                      : nav.displayIfUserIsLogged
                    : nav?.displayIfUserNotLogged ?? true,
                )
                .map(nav => (
                  <Button
                    href={nav.href}
                    buttonType={nav.buttonType}
                    key={nanoid(5)}
                    icon={nav.icon}
                    target={nav.target ?? '_self'}
                    className="relative"
                  >
                    {nav.label}
                    {nav?.isNew && (
                      <span className="inline-block bg-heliotrope text-white uppercase p-1 px-2 text-xs scale-75 transform absolute rounded-xl left-0">
                        New
                      </span>
                    )}
                  </Button>
                ))}
              {displayAuthOrProfileButton && !user && (
                <Button href={process.env.NEXT_PUBLIC_WEBAPP_URL} buttonType="secondary">
                  {getI18nKey({ id: 'header.button.connect' })}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
