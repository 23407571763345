import Link from 'next/link'

export const Alert = () => {
  return (
    <div className="w-80 bg-heliotrope fixed top-0 z-50 left-2/4 transform -translate-x-1/2 p-3.5 text-center space-y-2">
      <p>Vous êtes en mode preview.</p>
      <p>
        Pour quittez:{' '}
        <Link href="/api/exit-preview">
          <a className="underline">Cliquez ici</a>
        </Link>
      </p>
    </div>
  )
}
