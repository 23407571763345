import Link from 'next/link'

import { Icon } from 'src'
// import { Flags } from '../molecules/flags/Flags'

export const Footer = ({ links = [], socials = [], legals = [] }) => {
  return (
    <footer className="p-8 mt-24 space-y-8">
      <ul className="flex justify-center items-center flex-wrap">
        {links.map((link, index) => (
          <li key={index} className="py-3 px-8 md:px-16">
            <Link href={link.href}>
              <a target={link.target ?? '_self'} className="text-white text-base font-light">
                {link.label}
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <ul className="flex justify-center items-center space-x-8">
        {socials.map((social, index) => (
          <li key={index}>
            <Link href={social.href}>
              <a className="text-white text-base font-light">
                <Icon name={social.type} />
              </a>
            </Link>
          </li>
        ))}
      </ul>
      <div className="flex flex-col-reverse md:flex-row items-center space-y-8 md:space-y-0">
        <div className="w-20 hidden md:block" />
        <div className="flex-1">
          <p className="text-center text-sm text-cloud">
            © {new Date().getFullYear()} Majelan SAS
            {legals.map((legal, index) => (
              <Link href={legal.href} key={index}>
                <a>&nbsp;·&nbsp;{legal.label}</a>
              </Link>
            ))}
            <button
              onClick={() => {
                // @ts-ignore
                window.OneTrust.ToggleInfoDisplay()
              }}
            >
              &nbsp;·&nbsp;Gestion des cookies
            </button>
          </p>
        </div>
        <div className="w-20 flex justify-center md:justify-end text-center">{/* <Flags /> */}</div>
      </div>
    </footer>
  )
}
