import Link from 'next/link'
import classnames from 'classnames'
import { useIntl } from 'react-intl'
import { useRouter } from 'next/router'

import { Header } from './Header'
import { Footer } from './Footer'
import { Alert } from '../molecules/Alert'
import { useAuth } from '../providers/AuthProvider'

export const Layout = ({
  children,
  preview,
  showHeader = true,
  header = {
    logo: {
      url: '/mLogoFull.png',
      width: '444',
      height: '111',
      alt: '',
    },
    headerLeft: [],
    headerRight: [],
    logoMobile: {
      url: '/mLogo.png',
      width: '95',
      height: '101',
      alt: '',
    },
    displayAuthOrProfileButton: true,
    isLogoClickable: true,
  },
  footer = {
    links: [],
    socials: [],
    legals: [],
  },
}: any) => {
  return (
    <div className="max-w-screen-3xl m-auto">
      {preview && <Alert />}
      {showHeader && (
        <Header
          logo={header.logo}
          logoMobile={header.logoMobile}
          headerLeft={header.headerLeft}
          headerRight={header.headerRight}
          displayAuthOrProfileButton={header.displayAuthOrProfileButton}
          isLogoClickable={header.isLogoClickable}
        />
      )}
      {children}
      <Footer links={footer.links} socials={footer.socials} legals={footer.legals} />
    </div>
  )
}

const AUTH_NAV_LINKS = []

export const LayoutAuth = ({ children }: any) => {
  const { formatMessage: getI18nKey } = useIntl()

  const { asPath } = useRouter()
  const auth = useAuth()

  return (
    <div className="max-w-screen-3xl m-auto">
      <Header />
      <div className="flex md:space-x-20 pt-14 px-6 md:px-0 md:pt-20">
        <div className="hidden md:block w-52">
          <ul className="space-y-2">
            {AUTH_NAV_LINKS.map((elem, index) => (
              <li key={`auth-nav-${index}`}>
                <Link href={elem.href}>
                  <a
                    className={classnames(
                      'px-8 py-2 block relative text-base tracking-wide rounded-r-xl hover:bg-shark hover:auth-nav',
                      {
                        'bg-shark auth-nav text-white font-semibold': asPath.includes(elem.href),
                        'text-cloud font-normal': !asPath.includes(elem.href),
                      },
                    )}
                  >
                    {getI18nKey({ id: elem.label })}
                  </a>
                </Link>
              </li>
            ))}
          </ul>
        </div>
        <div className="flex-1 md:pr-7 lg:pr-0">
          <div className="bg-white rounded-2xl py-8 max-w-3xl mx-auto px-5 md:px-8 text-black">
            {children}
          </div>
        </div>
        <div className="hidden lg:block lg:w-52" />
      </div>
    </div>
  )
}
